<template>
  <div id="musicTools">
    <br>
    <Header title="Music Tools" subtitle="What I use to make music"/>
    <div id="toolList">
      <p>Here is a list of tools I currently use for music production.</p>
      <p>All tools listed are free, except the ones tagged with '($$$)'</p>
      <p>And quoting <a href="https://c418.org/2017/01/26/what-does-daniel-use-to-make-music/">C418 (Daniel)</a> here,
      "it doesn't matter what tools you use, try to get as much free stuff as you can and have fun!" and
      "It’s WAY MORE IMPORTANT that you know your stuff rather than how big your library is. There is NO point collecting a big database of stuff. Rather, learn with what you have already".</p>
      <br>
      <ul>
        <li><p><b>Digital Audio Workstation</b>: I'm currently using FL Studio, but I've used LMMS (Free) for some time before</p></li>
        <li><p><b>VST instruments</b>:</p>
          <ul>
            <li><p><b>Synths</b>:</p>
              <ul>
                <li v-for="item in synths" :key="item.name">
                  <p v-if="item.free"><b>{{ item.name }}</b>: {{ item.desc }}</p>
                  <p v-else><b>{{ item.name }}</b> ($$$): {{ item.desc }}</p>
                </li>
              </ul>
            </li>
            <li><p><b>Acoustic</b>:</p>
              <ul>
                <li v-for="item in acoustics" :key="item.name">
                  <p v-if="item.free"><b>{{ item.name }}</b>: {{ item.desc }}</p>
                  <p v-else><b>{{ item.name }}</b> ($$$): {{ item.desc }}</p>
                </li>
              </ul>
            </li>
            <li><p><b>Misc</b>:</p>
              <ul>
                <li v-for="item in misc" :key="item.name">
                  <p v-if="item.free"><b>{{ item.name }}</b>: {{ item.desc }}</p>
                  <p v-else><b>{{ item.name }}</b> ($$$): {{ item.desc }}</p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li><p><b>VST effects</b>:</p>
          <p>For VST effects I recommend using the ones that come with any DAW, as most of them are really good</p>
          <p>This list mostly contains effects I purposely had to search for, mostly for having more effects</p>
          <ul>
              <li v-for="item in effects" :key="item.name">
                <p v-if="item.free"><b>{{ item.name }}</b>: {{ item.desc }}</p>
                <p v-else><b>{{ item.name }}</b> ($$$): {{ item.desc }}</p>
              </li>
            </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Header from '@/components/Header';
  
  export default {
    name: 'MusicTools',
    data() {
      return {
        'synths': [
          {'name': 'Helm64',
          'free': true,
          'desc': 'synthesizer, I mostly use it for leads'},

          {'name': 'ZynAddSubFX',
          'free': true,
          'desc': 'synthesizer, hard to use interface but very powerful'},

          {'name': 'Serum',
          'free': false,
          'desc': 'wavetable synthesizer, clear interface and fun to make sounds with'},
        ],

        'acoustics': [
          {'name': 'AGML2',
          'free': true,
          'desc': 'acoustic guitar'},

          {'name': 'ABPL2',
          'free': true,
          'desc': 'bass guitar'},
          
          {'name': 'LABS',
          'free': true,
          'desc': 'lots of experimental acoustic sounds'},

          {'name': 'SForzando',
          'free': true,
          'desc': 'SFZ loader, no instrument samples included'},

          {'name': 'Sonatina Symphonic Orchestra',
          'free': true,
          'desc': 'good orchestral SFZ samples'},

          {'name': 'Versilian Studios Chamber Orchestra 2',
          'free': true,
          'desc': 'more good orchestral sounds'},
        ],

        'misc': [
          {'name': 'Kontakt 6 Player',
          'free': true,
          'desc': 'drums, pads, synths, acoustic instruments, a bit of everything'},

          {'name': 'XPand!2',
          'free': false,
          'desc': 'has decent acoustic instruments and superb ambience pads and synths'},
        ],

        'effects': [
          {'name': 'argotlunar',
          'free': true,
          'desc': 'granular synthesizer'},

          {'name': 'GRevDly',
          'free': true,
          'desc': 'for realtime reverse playback, not very precise'},

          {'name': 'GSnap',
          'free': true,
          'desc': 'pitch-correction for vocals'},

          {'name': 'LoudMax64',
          'free': true,
          'desc': 'quick brickwall limiter'},

          {'name': 'OTT',
          'free': true,
          'desc': 'easy to use 3-band compressor with great results'},

          {'name': 'TDR Nova',
          'free': true,
          'desc': 'really good equalizer, useful if your DAW doesn\'t have a decent one'},

          {'name': 'Valhalla Supermassive',
          'free': true,
          'desc': 'massive reverb for creating amazing soundscapes'},

          {'name': 'Ozone Elements',
          'free': false,
          'desc': 'for mastering'},
        ],
      }
    },
    components: {
      Header
    },
    methods: {
      handleScroll() {
        this.$parent.$refs.toolbar.handleScroll();
      }
    },
    mounted() {
      this.$el.addEventListener('scroll', this.handleScroll);
      for (var i = 1; i < 10; i++) {
        setTimeout(this.handleScroll, i*100);
      }
      this.handleScroll();
    },
    destroyed() {
      this.$el.removeEventListener('scroll', this.handleScroll);
    },
    metaInfo: {
      title: 'MusicTools'
    }
  }
</script>

<style lang="sass">
  @use '@/base'

  #toolList
    margin: -3em auto 4em auto
    width: 80%
    a
      color: #AAAAFF
    a:visited
      color: #8888FF
</style>